export interface IButton {
  onClick?: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  styles?: string;
  type?: 'submit' | 'button' | 'reset';
}

const Button = ({ onClick, children, disabled, styles, type }: IButton) => {
  return (
    <button
      type={type ? type : 'button'}
      className={`rounded-[8px] px-[16px] py-[12px] bg-secondary text-brown ${styles} ${disabled && 'opacity-50'}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
