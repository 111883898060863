import SuccessGif from 'assets/succes-gif.png';

export interface ISuccessBox {
  text: string;
}

const SuccessBox = ({ text }: ISuccessBox) => {
  return (
    <div className='max-w-lg rounded-[12px] bg-beige p-[32px] flex flex-col justify-center items-center'>
      <img src={SuccessGif} />
      <div className='bold text-grey800 font-[DKLiquidEmbrace] text-3xl text-center'>{text}</div>
    </div>
  );
};

export default SuccessBox;
