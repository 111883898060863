import { FieldError } from 'react-hook-form';

export interface IInput {
  type?: string;
  label: string;
  placeholder: string;
  value: string;
  onChange: () => void;
  onBlur: () => void;
  isTouched?: boolean;
  error?: FieldError | undefined;
}

const Input = ({ type, label, placeholder, value, onChange, onBlur, isTouched, error }: IInput) => {
  return (
    <div className='flex flex-col gap-[6px]'>
      <label className='text-xs font-[MuseoSansCyrl]'>{label}</label>
      <input
        className={`rounded-[8px] border ${error ? 'border-red-500' : 'border-grey300'} px-[10px] py-[8px] font-[MuseoSansCyrl] text-[14px] placeholder-text-grey500`}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {isTouched && error && <div className='text-red-500 text-[14px] font-[MuseoSansCyrl]'>{error.message}</div>}
    </div>
  );
};

export default Input;
