import _ from 'lodash';
import { useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { setSelfUserPassword } from 'entities/Auth/sdk';
import { getUrlGetParams } from 'utils/urls';

import AuthPage from 'components/AuthPage';
import Input from 'components/Input';
import Button from 'components/Button';
import SuccessBox from 'components/SuccessBox';

const schema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(
      new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\p{S}|\p{P}])/u, 'gu'),
      'Must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit and 1 special symbol.'
    ),
  passwordConfirmation: yup
    .string()
    .required('Password confirmation is required')
    .oneOf([yup.ref('password')], 'Passwords do not match, please try again.'),
});

interface ISetPasswordForm {
  password: string;
  passwordConfirmation: string;
}

const SetPassword = () => {
  const { token = '', email = '' } = getUrlGetParams();
  const [success, setSuccess] = useState<boolean>(false);
  const [formErrors, setErrors] = useState({ errors: {}, message: '' });

  const {
    handleSubmit,
    formState: { isValid, isSubmitted },
    control,
    reset,
  } = useForm<ISetPasswordForm>({
    defaultValues: { password: '', passwordConfirmation: '' },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<ISetPasswordForm> = ({ password, passwordConfirmation }) => {
    setErrors({ errors: {}, message: '' });
    setSelfUserPassword({ token, email, password, passwordConfirmation })
      .then(() => setSuccess(true))
      .catch((error) => {
        error.response?.text()?.then((data: string) => setErrors(JSON.parse(data)));
        reset({ password: '', passwordConfirmation: '' });
      });
  };

  return (
    <AuthPage>
      <div className='flex flex-col justify-center w-full'>
        {success && <SuccessBox text='your account has been created!' />}
        {!success && (
          <>
            <h1 className='bold text-grey800 font-[DKLiquidEmbrace] text-3xl pb-[24px] text-center md:text-left'>
              Set Password
            </h1>

            {!_.isEmpty(formErrors.errors) && (
              <div className='text-danger pb-[10px]'> {_.map(formErrors?.errors, (value: string) => value)}</div>
            )}
            {_.isEmpty(formErrors.errors) && formErrors.message !== '' && (
              <div className='text-danger pb-[10px]'>{formErrors.message}</div>
            )}

            <form onSubmit={handleSubmit(onSubmit)} className={'flex flex-col gap-[24px]'}>
              <Controller
                control={control}
                name='password'
                render={({ field, fieldState }) => (
                  <Input
                    type='password'
                    label='Create password'
                    placeholder='Enter a password for your account'
                    {...field}
                    {...fieldState}
                  />
                )}
              />
              <Controller
                control={control}
                name='passwordConfirmation'
                render={({ field, fieldState }) => (
                  <Input
                    type='password'
                    label='Confirm password'
                    placeholder='Confirm your password'
                    {...field}
                    {...fieldState}
                  />
                )}
              />
              <Button type='submit' styles={'w-full md:w-fit font-semibold'} disabled={!isValid || isSubmitted}>
                Create account
              </Button>
            </form>
          </>
        )}
      </div>
    </AuthPage>
  );
};

export default SetPassword;
