import AuthPage from 'components/AuthPage';

const NotFound = () => {
  return (
    <AuthPage>
      <h1 className='self-center bold text-grey800 font-[DKLiquidEmbrace] text-3xl pb-[24px] '>Page Not Found</h1>
    </AuthPage>
  );
};

export default NotFound;
