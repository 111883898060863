// import TripImage from 'assets/default-trip-image.png';
import TanzaniaSpecialistLogo from 'assets/TZ-logo.png';
import AfricaSafariLogo from 'assets/AS-logo.png';
import TripImage from 'assets/default-trip-image.png';
import SGRLogo from 'assets/sgr-logo.png';
import TCLogo from 'assets/tc-logo.png';
import ANVRLogo from 'assets/anvr-logo.png';

import Link from 'components/Link';

import './styles.css';

export interface IAuthPage {
  children: React.ReactNode;
}

const AuthPage = ({ children }: IAuthPage) => {
  return (
    <div className='flex flex-col justify-center'>
      {/* large screens top header */}
      <div className='hidden md:flex auth-page-layout pt-9 justify-center h-[220px]'>
        <div className='flex h-[66px] gap-6'>
          <img src={TanzaniaSpecialistLogo} width={200} />
          <img src={AfricaSafariLogo} width={142} />
        </div>
      </div>
      {/* end large screens top header */}
      {/* small screens top header */}
      <div className='md:hidden flex justify-center pt-[60px] auth-page-mobile-layout h-[320px]'>
        <div>
          <div className='flex justify-center gap-6 mb-[20px] h-[40px]'>
            <img src={TanzaniaSpecialistLogo} />
            <img src={AfricaSafariLogo} />
          </div>
          <img src={TripImage} width={300} />
        </div>
      </div>
      {/* end small screens top header */}
      <div className='self-center flex gap-[80px] flex-col p-[16px] lg:p-0 w-full lg:w-5xl max-w-[500px] lg:max-w-[1050px] lg:flex-row mt-[50px] md:mt-0'>
        <img src={TripImage} width={456} className='hidden lg:block' />
        {children}
      </div>

      <div className='self-center pt-[60px] flex flex-col items-center gap-[30px]'>
        <div className='flex items-center gap-[20px]'>
          <img src={TCLogo} />
          <img src={ANVRLogo} />
          <img src={SGRLogo} />
        </div>
        <div className='flex gap-[40px]'>
          <Link href='#'>Terms & Conditions</Link>
          <Link href='#'>Privacy Policy</Link>
        </div>
      </div>
    </div>
  );
};

export default AuthPage;
